import { HttpOdoo } from "../../service/http-common";
// initial state
const state = () => ({
  employees: [],
  isloading: false,
});

// getters
const getters = {
  getAllEmployees(state) {
    return state.employees;
  },
  getLoading(state) {
    return state.isloading;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  fetchAllEmployees(state) {
    return new Promise((resolve, reject) => {
      state.isloading = true;
      HttpOdoo.get("api/employees", {
        headers: {
          "content-type": "text/plain",
        },
      })
        .then((response) => {
          state.employees = response.data.data;
          resolve(state.employees);
          state.isloading = false;
          // this.fetchLoading(state);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllPrograms(state) {
    return new Promise((resolve, reject) => {
      state.isloading = true;
      HttpOdoo.get("api/programs", {
        headers: {
          "content-type": "text/plain",
        },
      })
        .then((response) => {
          state.programs = response.data.data;
          resolve(state.programs);
          state.isloading = false;
          // this.fetchLoading(state);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // fetchLoading(state) {
  //   state.isloading = false;
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
